import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Template from "@/views/Template";
import ProductPresentation from "@/components/ProductPresentation";
import ProductAdvantages from "@/components/ProductAdvantages";
import AboutProduct from "@/components/AboutProduct";
import Typography from "@/components/Typography";
import InvestTime from "@/containers/InvestTime";
import PlansBox from "@/components/PlansBox";
import Button from "@/components/Button/Button";
export const _frontmatter = {
  "title": "E-Social",
  "path": "/e-social",
  "date": "2019-01-29T00:00:00.000Z"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Template;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ProductPresentation advantages={["Registro da Carteira de Trabalho", "Controle de Jornada (Folha de Ponto)", "Cálculo da Folha de Pagamento", "Geração de Recibo (contracheque)", "Geração da Guia de Recolhimento do e-Social", "Cálculo de Férias", "Cálculo de 13º Salário ", "Vale-transporte"]} sideTitle="E-SOCIAL" contactLink="https://wa.me/5581994019577" knowMoreLink="https://www.instagram.com/leancontabilidade" description="Estão enquadrados nesta categoria cozinheiros, faxineiros, zeladores, jardineiros, motoristas particulares e cuidadores de idosos com vínculo empregatício (aqueles que trabalham três ou mais dias por semana)." title={() => <>
      Manter organizados os registros dos seus empregados domésticos é uma
      obrigação legal desde o início do e-Social.
    </>} mdxType="ProductPresentation" />{" "}
    <AboutProduct stepOne={() => <>Realizamos o cadastro do empregador no portal do e-Social.</>} stepTwo={() => <>A partir daí, fazemos todo o acompanhamento mensal, que inclui:</>} openLabel="Desejo ter um funcionário" hasLabel="Já tenho um funcionário" items={[[], ["Registro da Carteira de Trabalho", "Controle de Jornada (Folha de Ponto)", "Cálculo da Folha de Pagamento", "Geração de Recibo (contracheque)", "Geração da Guia de Recolhimento do e-Social", "Cálculo de Férias", "Cálculo de 13º Salário", "Vale-transporte"], []]} openLink="https://wa.me/5581994019577" hasLink="https://wa.me/5581994019577" knowMoreLink="https://www.instagram.com/leancontabilidade" mdxType="AboutProduct" />
    <InvestTime mdxType="InvestTime" />
    <ProductAdvantages description="Você economiza tempo e dinheiro. Confira ao lado as vantagens de ser um empregador doméstico e aproveite o alívio de estar cumprindo todas as obrigações legais." items={["Cumprir os requisitos legais;", "Prevenir problemas trabalhistas;", "Assegurar no futuro a aposentadoria do seu empregado;", "Em caso de licença maternidade, quem irá arcar com os custos deste período é o INSS;", "Não precisar arcar com despesas como salário e hospitais em caso de doença;", "Manter uma relação de confiança e transparência com o empregado;", "Tratar o empregado com respeito e honestidade;"]} title={() => <>
      Confira os principais{" "}
      <Typography as="span" weight={700} variant="h3" mdxType="Typography">
        Benefícios
      </Typography>{" "}
      de ser um{" "}
      <Typography as="span" weight={700} variant="h3" mdxType="Typography">
        Empregador Doméstico
      </Typography>
    </>} mdxType="ProductAdvantages" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      